export function getAPIBaseUrl() {
  if (process.env.NODE_ENV === 'production') {
    return '';
  }
  return '';
}

export const apiBasePath = getAPIBaseUrl();

function getConstantsAsDict() {
  if (process.env.NODE_ENV !== 'production') {
    Object.keys(devConstantDict).forEach(key => {
      defaultConstantDict[key] = devConstantDict[key]
    })
    return defaultConstantDict
  }
  return defaultConstantDict
}

const defaultConstantDict = {
  apiAuthPath: apiBasePath + '/api/auth',
  password: 'liubeisi',
  apiImagePath: apiBasePath + '/api/image',
  backgroundImageUpdateInterval: 0,
  postsUpdateInterval: 0,
  imageCompressionOptions: {
    maxSizeMB: 2,
    maxWidOrHeight: 1980
  },
  minifiedImageCompressionOptions: {
    maxSizeMB: 1,
    maxWidOrHeight: 250
  },
  apiThumbnailPath: apiBasePath + '/api/thumbnails',
}

const devConstantDict = {
  apiBasePath: 'http://127.0.0.1:8000',
  backgroundImageUpdateInterval: 0,
  postsUpdateInterval: 0
}

export const constantDict = getConstantsAsDict()

export const apiAuthPath = constantDict.apiAuthPath;

export const password = constantDict.password;

export const apiImagePath = constantDict.apiImagePath;

export const backgroundImageUpdateInterval = constantDict.backgroundImageUpdateInterval;

export const postsUpdateInterval = constantDict.postsUpdateInterval;

export const imageCompressionOptions = constantDict.imageCompressionOptions;

export const minifiedImageCompressionOptions = constantDict.minifiedImageCompressionOptions;

export const apiThumbnailPath = constantDict.apiThumbnailPath;