import axios from 'axios';
import { getAPIBaseUrl } from '../constants';
import { postsUpdateInterval } from '../constants';
import { v4 as uuid } from 'uuid';

const APIBaseUrl = getAPIBaseUrl()

export function getPosts({setPosts}) {
  axios.get(`${APIBaseUrl}/api/posts`, {
    'Access-Control-Allow-Origin': "http://127.0.0.1:3000",
    withCredentials: true,
  })
    .then(res => {
      const posts = res.data.posts;
      setPosts(posts);
    })
    .catch(err => console.log(err));

  if (postsUpdateInterval > 0) {
    const interval = setInterval(() => getPosts(setPosts), postsUpdateInterval)
    return () => clearInterval(interval);
  }
}

export function addPost({posts, newMsg, setPosts, setNewMsg}) {
  if (!newMsg) {
    return;
  }
  const newPost = {
    msg: newMsg,
    _id: uuid()
  }
  axios.post(`${APIBaseUrl}/api/posts`, newPost)
    .catch(err => console.log(err));

  setPosts([...posts, newPost]);
  setNewMsg('')
}

export function deletePost({id, setPosts, posts}) {
  axios.delete(`${APIBaseUrl}/api/posts?id=${id}`)
    .catch(err => console.log(err));
  
  const remainingPosts = posts.filter(post => {
    return post._id !== id
  })
  setPosts(remainingPosts);
}