import React, { useState } from 'react';
import olaf from '../images/OlafHeart.gif';
import cuddle from '../images/cuddle.gif';
import piney from '../images/piney.gif';
import Timer from './Timer';

export default function () {
  const [showOlaf, setShowOlaf] = useState(false);

  const handleTimerClick = () => {
    setShowOlaf(true);
    setTimeout(() => setShowOlaf(false), 1650)
  }
  return (
    <>
      <div className="clock-container">
        <div className={`${showOlaf ? 'hidden' : ''}`}>
          <Timer onClick={handleTimerClick} />
        </div>

        <img
          className={`${showOlaf ? '' : 'hidden'}`}
          id='olaf'
          alt="olaf heart"
          src={olaf}
        />
      </div>
      <img alt="cuddle" src={cuddle} />
      <img alt="piney" src={piney} />
    </>
  )
}