import React, { useState, useEffect } from 'react'
import {
  InputGroup,
  FormControl,
  Button,
  ListGroup,
  Spinner,
} from 'react-bootstrap';
import {
  getPosts,
  addPost,
  deletePost,
} from '../actions/post';
import Linkify from 'react-linkify';
import LinkifyDecorator from './LinkifyDecorator';


export default function PostSection() {
  const [posts, setPosts] = useState([]);
  const [newMsg, setNewMsg] = useState('');

  useEffect(() => {
    const cleanUpfunction = getPosts({ setPosts });
    return cleanUpfunction;
  }, [])

  const handleAdd = () => {
    addPost({ posts, newMsg, setPosts, setNewMsg });
  }

  const handleInput = e => {
    setNewMsg(e.target.value)
  }

  const handleDelete = id => {
    deletePost({ id, setPosts, posts });
  }

  return (
    <div style={{ opacity: 0.95, maxWidth: '800px', margin: 'auto' }}>
      <InputGroup className='mb-3'>
        <FormControl
          onChange={handleInput}
          value={newMsg}
          onKeyPress={e => e.key === 'Enter' && handleAdd()}
        />
        <InputGroup.Append>
          <Button
            style={{
              backgroundColor: '#f63b3b',
              border: '1px solid #f63b3b'
            }}
            onClick={handleAdd}
          > Add </Button>
        </InputGroup.Append>
      </InputGroup>
      <ListGroup >
        {posts.length > 0 ? posts.map((post, i) => (
          <ListGroup.Item key={i}>
            <Linkify componentDecorator={LinkifyDecorator}>
              {post.msg}
            </Linkify>
            <span
              style={{ float: 'right', color: 'grey', cursor: 'pointer' }}
              onClick={() => handleDelete(post._id)}
            >delete</span>
          </ListGroup.Item>
        )) : (<Spinner style={{ margin: 'auto' }} animation='grow' variant='light' />)
        }
      </ListGroup>
    </div>
  )
}