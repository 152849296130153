import React from 'react';
import Logout from './auth/Logout';
import { Button } from 'react-bootstrap';
import { getRandomImage } from '../actions/image';

export default function ({ 
  loggedIn, 
  setLoggedIn, 
  hidePosts, 
  setHidePosts, 
  setBackground, 
  background,
  hideGallery,
  setHideGallery
}) {
  const toggleHidePosts = () => {
    localStorage.setItem('hidePosts', !hidePosts);
    setHidePosts(!hidePosts);
    if (hidePosts) {
      setHideGallery(true);
    }
  }
  const toggleGallery = () => {
    setHideGallery(!hideGallery);
    if (hideGallery) {
      setHidePosts(true);
    }
  }

  const changeBackground = () => getRandomImage(setBackground)

  return (
    <div className={`${loggedIn ? '' : 'hidden'}`} style={{ float: 'right' }}>
      <Button
        style={{ margin: '10px'}}
        variant='outline-light'
        href={background}
        download
      >Download background</Button>
      <Button
        style={{ margin: '10px'}}
        variant='outline-light'
        onClick={changeBackground}
      >Change background</Button>
      <Button
        style={{ margin: '10px' }}
        variant='outline-light'
        onClick={toggleHidePosts}
      >{hidePosts ? 'Show posts' : 'Hide posts'}</Button>
      <Button
        style={{ margin: '10px' }}
        variant='outline-light'
        onClick={toggleGallery}
      >{hideGallery ? 'Show gallery' : 'Hide gallery'}</Button>
      <Logout setLoggedIn={setLoggedIn} />
    </div>
  )
}