import React from 'react';
import malaysia from '../../images/Malaysia.jfif';
import { Spinner } from 'react-bootstrap';


export default function ({ loggedIn, setLoggedIn, componenet: Component, authComponent: Login }) {

  if (loggedIn === undefined) {
    return (
      <>
        <div className="display-container">
          <div className='background' style={{ backgroundImage: `url(${malaysia})` }}></div>
          <Spinner animation="border" role="status" variant="danger" />
        </div>
      </>
    )
  }
  if (loggedIn) {
    return <Component loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
  }

  return <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} />

}