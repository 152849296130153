import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { password } from './constants';
import PrivateComponent from './components/auth/PrivateComponent';
import Home from './routes/Home';
import Login from './routes/Login';
import { authenticate } from './actions/auth';


function App() {
  const [loggedIn, setLoggedIn] = useState(undefined)

  useEffect(() => {
    authenticate({password, setLoggedIn})
  }, [])

  return (
    <div className="App">
      <PrivateComponent
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
        authComponent={Login}
        componenet={Home}
      />
    </div>
  );
}

export default App;
