import React, { useState } from 'react';
import { uploadImage } from '../actions/image';
import { Button, Spinner } from 'react-bootstrap';

export default function ImageUploader({ setThumbnails}) {
  const [loading, setLoading] = useState(false);
  const handleUpload = e => {
    uploadImage({e, setThumbnails, setLoading})
  }

  return <>
    <Button
      style={{ margin: '10px' }}
      variant='outline-light'
    >
      <label
        htmlFor='file-upload'
        style={{ margin: 0, cursor: 'pointer' }}
      >Upload image</label>
      <span>{' '}</span>
      {loading && <Spinner
        style={{margin:'5px, 5px'}}
        as='span'
        size='sm'
        role='status'
        animation='border' 
        variant='info'
      >{' '}</Spinner>}
      

      <input
        id='file-upload'
        className='hidden'
        type='file'
        onChange={handleUpload}
      />
    </Button>
  </>
}