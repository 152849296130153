import axios from 'axios';
import {
  apiImagePath,
  apiThumbnailPath,
} from '../constants';
import { imageCompressionOptions, minifiedImageCompressionOptions } from '../constants';
import imageCompression from 'browser-image-compression';
import { fileToDataUrl } from '../helpers';

export async function uploadImage({e, setThumbnails, setLoading}) {
  setLoading(true);
  const file = e.target.files[0];
  const image = imageCompression(file, imageCompressionOptions);
  const thumbnail = imageCompression(file, minifiedImageCompressionOptions);

  const compressedPayload = await Promise.all([image, thumbnail]);

  const dataUrls = await Promise.all(
    compressedPayload.map(imgFile => fileToDataUrl(imgFile))
  );

  const bodyFormData = new FormData();
  bodyFormData.set('image', dataUrls[0]);
  bodyFormData.set('thumbnail', dataUrls[1]);

  axios({
    method: 'POST',
    url: apiImagePath,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then(res => {
      getThumbnails(setThumbnails);
      setLoading(false);
    })
    .catch(err => console.log(err.response.data))
}

export function getRandomImage(setImage) {
  axios.get(apiImagePath)
    .then(res => {
      if (!res) {
        console.log('getRandomImage did not provide a response')
      }
      setImage(res.data.image.image)
    })
    .catch(err => console.log(err))
}

export function getBackground({selected, setBackground}) {
  axios.get(`${apiImagePath}?id=${selected}`)
  .then(res => {
    setBackground(res.data.image.image)
  })
  .catch(err => console.log(err))
}

export async function getThumbnails({setThumbnails, setLoading}) {
  setLoading(true);
  let more = true;
  let thumbnails = [];
  let lastId = '';
  for (let i=0; i<10; i++) {
    if (more){
      try{
        let {data: {images, moreInThisDir}} = await axios.get(
          `${apiThumbnailPath}?lastId=${lastId}`
          )
        more = moreInThisDir
        thumbnails = thumbnails.concat(images)
        setThumbnails(thumbnails)
        lastId = images[images.length-1]._id
      }catch(err){
        console.log(err)
      }
    }
  }
  setLoading(false);
  
}

export function deleteImage({selected, thumbnails, setThumbnails}) {
  console.log('deleting id:', selected);
  axios.delete(`${apiImagePath}?id=${selected}`)
    .then(res => console.log(res.data))
    .catch(err => console.log(err))
  setThumbnails(
    thumbnails.filter(thumbnail => thumbnail._id !== selected)
  )
}