import axios from 'axios';
import { apiAuthPath } from '../constants';
import { password as appPassword } from '../constants'

export function logout({setLoggedIn}) {
  setLoggedIn(false)
  localStorage.removeItem('password')
}

export function login({ username, password, setLoggedIn, setPassword }) {
  axios.post(`${apiAuthPath}/login`, { username, password })
    .then(res => {
      if (password === appPassword) {
        setLoggedIn(true);
        localStorage.setItem('password', appPassword);
        console.log(res.data)
      }
    })
    .catch(err => console.log(err.response.data));
  setPassword('');
}

export function authenticate({password, setLoggedIn}) {
  if (localStorage.getItem('password') === password) {
    setLoggedIn(true)
  } else {
    setLoggedIn(false)
  }
}