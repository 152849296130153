import React, { useEffect, useState } from 'react';
import { getTime } from '../helpers';

export default function (props) {

  const [time, setTime] = useState('thinking...')
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getTime())
    }, 1000)

    return function () {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <p id="clock" onClick={props.onClick}>{time}</p>
    </>
  )
}