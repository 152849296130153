import React, { useState } from 'react';
import {
	InputGroup,
	FormControl,
	Button,
} from 'react-bootstrap';
import { login } from '../../actions/auth';

export default function Login({ setLoggedIn }) {
	const [password, setPassword] = useState('')

	const handleSubmit = () => login({password, setLoggedIn, setPassword})
	return (
		<InputGroup style={{ maxWidth: '250px', margin: 'auto' }}>
			<FormControl
				placeholder='password'
				onChange={e => setPassword(e.target.value)}
				value={password}
				onKeyPress={e => e.key === 'Enter' && handleSubmit()}
			/>
			<InputGroup.Append>
				<Button
					onClick={handleSubmit}
				>Submit</Button>
			</InputGroup.Append>
		</InputGroup>
	)
};