import React from 'react';
import Login from '../components/auth/Login';
import malaysia from '../images/Malaysia.jfif';

export default function ({loggedIn, setLoggedIn}) {
  return (
    <>
      <div className='background' style={{ backgroundImage: `url(${malaysia})` }}></div>
      <div className="display-container">
        <Login
          loggedIn={loggedIn}
          setLoggedIn={setLoggedIn}
          className='login'
        />
      </div>
    </>
  )
}