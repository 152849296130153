import React, { useEffect, useState} from 'react';
import { Image, Spinner, Button } from 'react-bootstrap';
import { getThumbnails, deleteImage, getBackground } from '../actions/image';
import ImageUploader from './ImageUploader'

export default function ({setBackground}) {
  const [thumbnails, setThumbnails] = useState([]);
  const [selected, setSelected] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(()=> {
    getThumbnails({setThumbnails, setLoading});
    
  }, []);

  const handleSelect = id => {
      setSelected(selected === id ? undefined: id)
  }
  const handleDelete = () => {
    if (confirmed){
      deleteImage({selected, thumbnails, setThumbnails})
      setConfirmed(false);
      setSelected('');
    }else{
      setConfirmed(true);
    }
  }
  const handleSetBackground = () => {
    getBackground({selected, setBackground});
  }

  return (
    <div style={{textAlign: 'center'}}>
      <div className='dimmer'></div>
      <div>
        <ImageUploader setThumbnails={setThumbnails} />
        <Button
          style={{ margin: '10px'}}
          variant='outline-light'
          onClick={handleSetBackground}
        >Set background</Button>
        {!!selected && <Button
          style={{ margin: '10px'}}
          variant={confirmed ? 'danger':'outline-danger'}
          onClick={handleDelete}
        >{confirmed ? 'Confirm delete?': 'Delete Selected'}</Button>}
      </div>

      {thumbnails.map((item, i) => {
        return (
          <div 
            key={i}
            style={{
              position: 'relative', 
              display: 'inline-block',
              background: item._id === selected ? 'white':'none'

            }}
            onClick={() => handleSelect(item._id)}
            >
            
            <Image 
            style={{height:'150px', width:'auto', margin:'10px 10px'}} 

            src={item.thumbnail}
            />
          </div>
        )
      })}
      {loading && <div style={{ 
          height: '150px', 
          width: 'auto', 
          margin: '10px 10px', 
          display: 'inline-block',
          }}
        >
          <Spinner
            animation='grow' 
            variant='light'
          />
        </div>
      }

    </div>
  );
}