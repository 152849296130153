export function getTime() {
  const today = Date.now()
  var targetDay = new Date();
  targetDay.setDate(27);
  targetDay.setMonth(5);
  targetDay.setHours(23, 0, 0, 0);
  const days = parseInt((targetDay - today) / 1000 / 3600 / 24);
  const hours = parseInt((targetDay - today) / 1000 / 3600) % 24;
  const minutes = parseInt((targetDay - today) / 1000 / 60) % 60;
  const seconds = parseInt((targetDay - today) / 1000 % 3600) % 60;
  const time = `${days}d ${hours}h ${minutes}m ${seconds}s`
  return time
}

export function fileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => resolve(fr.result);
    fr.onerror = () => reject(fr.error);
    fr.readAsDataURL(file);
  })
}