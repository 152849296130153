import React from 'react';
import { Button } from 'react-bootstrap';
import { logout } from '../../actions/auth';

export default function Logout({ setLoggedIn }) {
  return <Button
    style={{ margin: '10px' }}
    variant='outline-light'
    onClick={() => logout({setLoggedIn})}
  >Logout</Button>
};