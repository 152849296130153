import React, { useState, useEffect } from 'react';
import HeroTimer from '../components/HeroTimer';
import PostSection from '../components/PostSection';
import Header from '../components/Header';
import malaysia from '../images/Malaysia.jfif';
import Gallery from '../components/Gallery';
import { getRandomImage } from '../actions/image';

export default function ({ loggedIn, setLoggedIn }) {
  const [hidePosts, setHidePosts] = useState(
    localStorage.getItem('hidePosts') === 'true'
  )
  const [hideGallery, setHideGallery] = useState(true)
  const [background, setBackground] = useState(malaysia)

  useEffect(() => {
    getRandomImage(setBackground)
  }, [])
  return (
    <>
      <div className='background' style={{
        backgroundImage: `url(${background})`
      }}></div>

      <Header
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
        hidePosts={hidePosts}
        setHidePosts={setHidePosts}
        setBackground={setBackground}
        background={background}
        hideGallery={hideGallery}
        setHideGallery={setHideGallery}
      />
      <div className="display-container">
        <HeroTimer />
        <div className={`${hidePosts ? 'hidden' : ''}`}>
          <PostSection />
        </div>
      </div>
      <div className={`${hideGallery ? 'hidden' : ''}`}>
        <Gallery setBackground={setBackground} />
      </div>

    </>
  )
}